import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { Pagination } from 'swiper/modules';
// import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';

const Gallery = () => {
  
  return (
    <>
    <section id='gallery'>
        <Swiper
        slidesPerView={1}
        spaceBetween={30}
        pagination={{
            clickable: true,
        }}
        breakpoints={{
            425: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            640: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 40,
            },  
            1024: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
        }}
        modules={[Pagination]}
        className="mySwiper"
        >
        <SwiperSlide><img src="/assets/img/img-1.jpg" width={500} alt="img-1" /></SwiperSlide>
        <SwiperSlide><img src="/assets/img/img-4.jpg" width={500} alt="img-4" /></SwiperSlide>
        <SwiperSlide><img src="/assets/img/img-2.jpg" width={500} alt="img-2" /></SwiperSlide>
        <SwiperSlide><img src="/assets/img/img-8.jpg" width={500} alt="img-8" /></SwiperSlide>
        <SwiperSlide><img src="/assets/img/img-9.jpg" width={500} alt="img-9" /></SwiperSlide>
        <SwiperSlide><img src="/assets/img/img-3.jpeg" width={500} alt="img-3" /></SwiperSlide>
        <SwiperSlide><img src="/assets/img/img-5.jpeg" width={500} alt="img-5" /></SwiperSlide>
        <SwiperSlide><img src="/assets/img/img-6.jpeg" width={500} alt="img-6" /></SwiperSlide>
        <SwiperSlide><img src="/assets/img/img-7.jpeg" width={500} alt="img-7" /></SwiperSlide>
        </Swiper>
    </section>
  </>
  )
}

export default Gallery