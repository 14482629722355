import React from 'react'
import '../footer/footer.css'
// import './style1.css';

const Footer = () => {
  return (
    <>
    <section id='footer'>
        <div className="footer1">
            <div className="container">
                <h3>NIEC Yogyakarta Student Hub & Co-Working Space</h3>
                <div className="contact">
                <h5>Contact Person</h5>
                <a href="tel:+628995298764">+62 899-5298-764 (Ida)</a>
                </div>
                <ul>
                    <li><a href="#header">Home</a></li>
                    <li><a href="#pricing">Pricing</a></li>
                    <li><a href="#mailbox">Virtual Mailbox</a></li>
                    <li><a href="#why-us">Why Us</a></li>
                </ul>
            </div>
        </div>
        <div className="footer2">
            <div className="container">
                <p>NIEC Team All Right Reserved</p>
            </div>
        </div>
    </section>
    </>
  )
}

export default Footer