import React, { useEffect, useState } from 'react'
import '../header/header.css'
import RegisTest from '../form/RegisTest'

const Header = ({paket, tipe_paket}) => {
  const [clicked, setClicked] = useState(false)
  const [sticky, setStickyClass] = useState(false)
  const menu = [
    {id: 1,title: 'Home', href: '#header'},
    {id: 2,title: 'Pricing', href: '#pricing'},
    {id: 3,title: 'Virtual Mailbox', href: '#mailbox'},
    {id: 4,title: 'Why', href: '#why-us'},
  ];

  useEffect(() => {
    window.addEventListener('scroll', stickNavbar);
    return () => window.removeEventListener('scroll', stickNavbar);
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 10 ? setStickyClass('active') : setStickyClass('');
    }
  }

  const handleClick = () => {
    setClicked(!clicked)
  }
  return (
    <div className="header" id='header'>
        <div className={`navbar ${sticky ? `active` : ''}`}>
            <div className="container">
            <div className="navbar-logo">
                <h2>NIEC Student Hub & <br /> Co-Working Space <br />Yogyakarta</h2>
            </div>
          <div className='nav-menu'>
              <ul className={`nav-list ${clicked ? `active` : ''}`}>
                  {
                    menu.map((m) => {
                    return (
                        <li onClick={handleClick} key={m.id}>
                        <a href={m.href}>{m.title}</a>
                    </li>
                    )
                    })
                }
              </ul>
          </div>
          <div className="bar-menu" onClick={handleClick}>
            <i className={`fa-solid  ${!clicked ? `fa-bars-staggered` : 'fa-times'}`}></i>
          </div>
            </div>
        </div>
        <div className="container banner">
        <div className="row">
            <div className="col-lg-6 col-md-6 banner-1">
                <h1 className='banner_title'>Tingkatkan Efisiensi <br />dan Fokus Kerja Anda</h1>
                <p className='banner_paragraph'>Maksimalkan potensi kerjamu dengan ruang kerja yang super produktif, Pesan Sekarang!</p>
            </div>
            <div className="col-lg-6 col-md-6">
                {/* <FormRegister/> */}
                <RegisTest selectedPaket={paket} tipe_paket={tipe_paket}/>
            </div>
        </div>
        </div>
    </div>
  )
}

export default Header